import { useEffect, useState } from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { Box, Typography, LinearProgress, Stack, CardContent, Card } from '@mui/material';
import { ContactFindOneByEmailResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import { FormField } from '@/src/components/CreateNewCustomerDrawer/FormField';
import { CustomerEnrichmentLine } from '@/src/components/CustomerEnrichment/components/CustomerEnrichmentLine';
import MagicAnimatedIconSrc from './assets/magic-animated-icon.lottie';
type CustomerDetailsFormProps = {
  enrichment?: ContactFindOneByEmailResponse['enrichment'];
};
export const CustomerDetailsForm = ({
  enrichment
}: CustomerDetailsFormProps) => {
  const [progress, setProgress] = useState(0);
  const isEnrichmentDefined = Boolean(enrichment);
  useEffect(() => {
    if (!isEnrichmentDefined) {
      return;
    }
    const timer = setInterval(() => {
      setProgress(previousProgress => {
        if (previousProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return previousProgress + 20;
      });
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, [isEnrichmentDefined]);
  return <Box data-sentry-element="Box" data-sentry-component="CustomerDetailsForm" data-sentry-source-file="CustomerDetailsForm.tsx">
      <Box sx={{
      height: 70,
      position: 'relative'
    }} data-sentry-element="Box" data-sentry-source-file="CustomerDetailsForm.tsx">
        <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      }} data-sentry-element="Box" data-sentry-source-file="CustomerDetailsForm.tsx">
          <LinearProgress variant="determinate" value={progress} color="secondary" sx={{
          bgcolor: 'initial'
        }} data-sentry-element="LinearProgress" data-sentry-source-file="CustomerDetailsForm.tsx" />
        </Box>
        <Stack direction="row" sx={{
        justifyContent: 'space-between',
        pt: 2
      }} data-sentry-element="Stack" data-sentry-source-file="CustomerDetailsForm.tsx">
          <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="CustomerDetailsForm.tsx">Customer Details</Typography>
          {!!enrichment && <Box sx={theme => ({
          width: 50,
          height: 50,
          path: {
            fill: theme.palette.secondary.main
          }
        })}>
              {progress > 50 && <DotLottiePlayer src={MagicAnimatedIconSrc} autoplay />}
            </Box>}
        </Stack>
      </Box>
      <FormField name="firstName" label="First name" enriched={Boolean(enrichment?.firstName)} data-sentry-element="FormField" data-sentry-source-file="CustomerDetailsForm.tsx" />
      <FormField name="lastName" label="Last name" enriched={Boolean(enrichment?.lastName)} data-sentry-element="FormField" data-sentry-source-file="CustomerDetailsForm.tsx" />
      <FormField name="email" label="Email address" disabled enriched={Boolean(enrichment?.email)} data-sentry-element="FormField" data-sentry-source-file="CustomerDetailsForm.tsx" />
      <FormField name="phone" label="Phone" enriched={Boolean(enrichment?.phone)} data-sentry-element="FormField" data-sentry-source-file="CustomerDetailsForm.tsx" />
      {!!enrichment && <Box sx={{
      mt: 1
    }}>
          <Card>
            <CardContent>
              <Stack direction="row" sx={{
            justifyContent: 'space-between'
          }}>
                <Box>
                  {(enrichment.firstName || enrichment.lastName) && <Typography variant="h6" sx={{
                color: 'text.secondary'
              }}>
                      {enrichment.firstName} {enrichment.lastName}
                    </Typography>}
                </Box>
                <Box>
                  {enrichment.email && <CustomerEnrichmentLine text={`Email: ${enrichment.email}`} source="external" />}
                  {enrichment.phone && <CustomerEnrichmentLine text={`Phone number: ${enrichment.phone}`} source="external" />}
                  {enrichment.jobTitle && <CustomerEnrichmentLine text={`Job Title: ${enrichment.jobTitle}`} source="external" />}
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Box>}
    </Box>;
};