import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { range } from 'lodash';
import differenceBy from 'lodash/differenceBy';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, InputAdornment, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { isString } from '@/shared/types/util/TypeGuards';
import { Part, PartWithQty } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
const CHARACTER_THRESHOLD = 2;
type PartAutocompleteProps = {
  selectedParts: PartWithQty[];
  onPartClick: (part: Part) => void;
};
// This Autocomplete is too specific to Create New Quote flow but it could be refactored as Multiple Select Part Autocomplete
export const PartAutocomplete = ({
  onPartClick,
  selectedParts
}: PartAutocompleteProps) => {
  const tenantAlias = useTenantAlias();
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('');
  const [open, setOpen] = useState(false);
  const enabled = debouncedQuery.trim().length >= CHARACTER_THRESHOLD;
  const {
    data: parts = [],
    isLoading
  } = useQuery({
    ...spaQueries.search.partAutocomplete({
      query: debouncedQuery
    }, tenantAlias),
    enabled
  });
  useEffect(() => {
    /** Close the dropdown immediately after user deletes characters after the threshold to avoid showing empty states */
    if (query.length <= CHARACTER_THRESHOLD) {
      setOpen(false);
    }
  }, [query]);
  useEffect(() => {
    /** Open the dropdown only after user stops typing */
    if (debouncedQuery.length >= CHARACTER_THRESHOLD) {
      setOpen(true);
    }
  }, [debouncedQuery]);
  return <Autocomplete disableClearable fullWidth clearOnBlur={false} inputValue={query} freeSolo onInputChange={(_event, newInputValue, reason) => {
    if (reason === 'reset') {
      setQuery('');
      return;
    }
    setQuery(newInputValue);
  }} onChange={(_event, newValue) => {
    if (isPart(newValue)) {
      onPartClick(newValue);
    }
  }}
  // filter options so only those that are not already in selectedParts are shown
  filterOptions={options => differenceBy(options, selectedParts, 'id')} getOptionLabel={option => isString(option) ? option : option.partNumber} options={parts} open={open} onClose={() => setOpen(false)} renderInput={params => <TextField {...params} fullWidth label="Type part number" inputProps={{
    ...params.inputProps,
    maxLength: 500
  }} sx={{
    mb: 0
  }} InputProps={{
    ...params.InputProps,
    endAdornment: <InputAdornment position="end" sx={{
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'primary.main',
      maxHeight: '100%',
      height: 56,
      width: 56,
      margin: 0,
      pointerEvents: 'none'
    }}>
                <SearchIcon sx={{
        color: 'primary.contrastText'
      }} />
              </InputAdornment>
  }} />} renderOption={(props, option) => {
    const {
      key,
      ...optionProps
    } = props;
    return <Stack direction="row" component="li" key={key} spacing={2} {...optionProps} sx={[{
      justifyContent: 'center',
      alignItems: 'flex-start',
      px: 2,
      py: 1,
      boxShadow: theme => `0 1px 0 0 ${theme.palette.divider}`
    }]}>
            <ImageWithFallback tenantAlias={tenantAlias} src={option.images[0]?.target} alt={option.partNumber} width={50} height={50} />
            <Stack spacing={1} sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'space-between'
      }}>
              <Stack direction="row" sx={{
          justifyContent: 'space-between',
          width: '100%'
        }}>
                <Typography variant="h6">{option.partNumber}</Typography>
                <Typography variant="h6" noWrap>
                  {option.manufacturer.name}
                </Typography>
              </Stack>
              <Typography variant="bodyMedium" sx={clampLinesSx(2)}>
                {option.description}
              </Typography>
            </Stack>
          </Stack>;
  }} PaperComponent={({
    children
  }) => <Paper square sx={{
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& .MuiAutocomplete-listbox': {
      py: 0
    }
  }}>
          {isLoading && enabled ? <LoadingSkeleton /> : <>{children}</>}
        </Paper>} noOptionsText={<NoOptions />} popupIcon={null} data-sentry-element="Autocomplete" data-sentry-component="PartAutocomplete" data-sentry-source-file="PartAutocomplete.tsx" />;
};
const LoadingSkeleton = () => <Box sx={{
  px: 4,
  pt: 2
}} data-sentry-element="Box" data-sentry-component="LoadingSkeleton" data-sentry-source-file="PartAutocomplete.tsx">
    {range(3).map((_, i) => <Stack key={i} sx={{
    mb: 2
  }}>
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" height={50} />
      </Stack>)}
  </Box>;
const NoOptions = () => <Stack spacing={2} sx={{
  px: 2,
  py: 2
}} data-sentry-element="Stack" data-sentry-component="NoOptions" data-sentry-source-file="PartAutocomplete.tsx">
    <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="PartAutocomplete.tsx">
      Unfortunately we couldn’t find this part number in our database.
    </Typography>
  </Stack>;
const isPart = (part: unknown): part is Part => {
  return typeof part === 'object' && part !== null && 'partNumber' in part;
};