import { Fragment, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { ListActions } from 'react-use/lib/useList';
import { useMutation, useQuery } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Button, Card, CardContent, Divider, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactAutocompleteResponse } from '@/shared/types/controllers/SearchControllerTypes';
import { CreateCustomPartResponse } from '@/shared/types/controllers/spa/SPACustomPartControllerTypes';
import { isNotEmpty } from '@/shared/types/util/TypeGuards';
import { PartAutocomplete } from '@/src/components/Common/Autocomplete/PartAutocomplete';
import { PartToAddCardContent } from '@/src/components/Common/PartToAddCardContent';
import { Part, PartWithQty } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { RequestedPart } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/RequestedPart';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { CustomPartForm, CustomPartFormData } from './CustomPartForm';
type RequestedPartsProps = {
  selectedParts: PartWithQty[];
  selectedPartsListActions: ListActions<PartWithQty>;
  selectedContact: ContactAutocompleteResponse['contacts'][0] | null;
};
export const RequestedParts = ({
  selectedParts,
  selectedPartsListActions,
  selectedContact
}: RequestedPartsProps) => {
  const tenantAlias = useTenantAlias();
  const [partToAdd, setPartToAdd] = useState<Part | null>(null);
  const [isAddingCustomPart, setIsAddingCustomPart] = useState(false);
  const findOneContactRequest = useQuery({
    ...spaQueries.contact.findOne(tenantAlias, Number(selectedContact?.id)),
    enabled: isNotEmpty(selectedContact)
  });
  const createCustomPartRequest = useMutation({
    ...spaQueries.customPart.create(tenantAlias),
    onSuccess: () => {
      toast.success('Custom part has been created successfully');
    },
    onError: () => {
      toast.error(`Couldn't create custom part, please try again`);
    }
  });
  const onPartClick = useCallback((part: Part | null) => {
    setPartToAdd(part);
  }, []);
  const handleCustomPartSubmit = (data: CustomPartFormData) => {
    createCustomPartRequest.mutate({
      partNumber: data.partNumber,
      manufacturerName: data.manufacturer,
      description: data.description
    }, {
      onSuccess: ({
        customPartRevision
      }: CreateCustomPartResponse) => {
        selectedPartsListActions.push({
          ...data,
          manufacturer: {
            name: data.manufacturer ?? ''
          },
          id: customPartRevision.id,
          images: [{
            target: ''
          }],
          isCustomPartRevision: true
        });
        setIsAddingCustomPart(false);
      }
    });
  };
  return <>
      <Grid xs={12} lg={6} data-sentry-element="Grid" data-sentry-source-file="RequestedParts.tsx">
        <Typography variant="h4" sx={{
        mb: 2
      }} data-sentry-element="Typography" data-sentry-source-file="RequestedParts.tsx">
          Add Parts
        </Typography>
        <PartAutocomplete onPartClick={onPartClick} selectedParts={selectedParts} data-sentry-element="PartAutocomplete" data-sentry-source-file="RequestedParts.tsx" />
        <Button onClick={() => setIsAddingCustomPart(true)} size="small" startIcon={<AddIcon />} sx={{
        mt: 2
      }} data-sentry-element="Button" data-sentry-source-file="RequestedParts.tsx">
          Add Non-standard Product or Service
        </Button>

        {isAddingCustomPart && <Card sx={{
        width: '100%',
        mt: 2,
        backgroundColor: 'primary.4p'
      }}>
            <CardContent>
              <CustomPartForm onSubmit={handleCustomPartSubmit} isLoading={createCustomPartRequest.isLoading} />

              <Button onClick={() => setIsAddingCustomPart(false)} size="small" startIcon={<ExpandLessIcon />} sx={{
            mt: 2
          }}>
                Close
              </Button>
            </CardContent>
          </Card>}

        {partToAdd && <Card sx={{
        width: '100%',
        mt: 2
      }}>
            <CardContent>
              <PartToAddCardContent part={partToAdd} key={partToAdd.id} onAddToQuote={(part, qty) => {
            selectedPartsListActions.upsert((a, b) => a.id === b.id, {
              ...part,
              qty
            });
            setPartToAdd(null);
          }} customerID={findOneContactRequest.data?.contact?.user?.company} />
            </CardContent>
          </Card>}
      </Grid>
      {selectedParts.length > 0 && <Grid xs={12} lg={6}>
          <Typography variant="h4" sx={{
        mb: 2
      }}>
            Requested Parts
          </Typography>
          <Stack spacing={2}>
            {selectedParts.map((part, index) => {
          const isLast = index === selectedParts.length - 1;
          return <Fragment key={part.id}>
                  <RequestedPart part={part} index={index} selectedPartsListActions={selectedPartsListActions} />
                  {!isLast && <Divider />}
                </Fragment>;
        })}
          </Stack>
        </Grid>}
    </>;
};